export const endpointUrl = process.env.REACT_APP_PROPERTY_ADMIN_GRAPHQL ?? ''
export const propertyAPI = process.env.REACT_APP_PROPERTY_API || ''
export const bafUrl = process.env.REACT_APP_BAF_URL || ''
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT ?? ''
export const environment = process.env.REACT_APP_ENV || ''
export const isInProduction = environment === 'production' || environment === 'release'
export const isInDevelopment = environment === 'development'
export const isInTest = environment === 'test'
export const isInConversion = environment === 'conversion'

const title: { [key: string]: string } = {
  development: 'DEV',
  test: 'TEST',
  conversion: 'CONV',
  release: 'RELEASE',
}
export const showEnv = title[environment] ?? ''
