import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

export type LoaderProps = {
  loading: boolean
}

export default function Loader(props: LoaderProps): JSX.Element {
  const classes = useStyles()

  if (!props.loading) return <></>

  return (
    <Box className={classes.loader} data-testid="mui-circular-loader">
      <Box className={classes.spinner}>
        <CircularProgress />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '1',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  spinner: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
